








// import React, { useState, useEffect, useCallback } from 'react';
// import './App.css';
// import CryptoJS from 'crypto-js'; // Import crypto-js for AES encryption/decryption
// import Fingerprint2 from 'fingerprintjs2'; // Import Fingerprint2 for generating device ID
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Import necessary components


// const useIsElectron = () => {
//   const [isElectron, setIsElectron] = useState(false);

//   useEffect(() => {
//       if (typeof window !== "undefined" && window.process && window.process.versions && window.process.versions.electron) {
//           setIsElectron(true);
//       }
//   }, []);

//   return isElectron;
// };







// const IV_LENGTH = 16; // AES IV length
// const ENCRYPTION_KEY = 'x988707222798870722279887072227x'; // AES-256 encryption key (must be 32 bytes)

// // Sample Employee data
// const employees = [
//   { employeeId: 'OKZIM000123', name: 'ASHISH JAIN', email: 'aj@getransfer.co.zw', phone: '91.98870.72227', merchantId: 'okZimbabwexx01' },
// ];
// // import { ipcRenderer } from 'electron'; // Directly import ipcRenderer

// // const isElectron = () => {
// //   return typeof window !== 'undefined' && window.process && window.process.type === 'renderer';
// // };
// // Whitelist devices
// const whitelistDevices = [
//   { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: 'device123', status: 'inactive' },
//   { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '527c80f118e70acfa27877c818aede08', status: 'active' },
//   { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '3df19d8978dd6229fbb62bf76fe6988f', status: 'inactive' },
//   { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '22df6337f288e481f53a78a5e601f97f', status: 'inactive' },
//   { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '956942087fe19fd07f9f613e750ba202', status: 'active' },
//   { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '4dfd099fbfe09a7e319ff9b29129b0e9', status: 'active' },
// ];

// // Helper function to convert WordArray to Base64
// function wordArrayToBase64(wordArray) {
//   return CryptoJS.enc.Base64.stringify(wordArray);
// }

// // Helper function to convert Base64 to WordArray
// function base64ToWordArray(base64String) {
//   return CryptoJS.enc.Base64.parse(base64String);
// }

// // Encryption function using AES-256
// const encrypt = (text) => {
//   const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);
//   const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
//   const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
//   return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString();
// };

// // Decryption function using AES-256
// const decrypt = (encryptedData) => {
//   const [ivString, encryptedText] = encryptedData.split(':');
//   const iv = CryptoJS.enc.Base64.parse(ivString);
//   const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
//   const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
//   return decrypted.toString(CryptoJS.enc.Utf8);
// };

// function App() {

//   const isElectron = useIsElectron();
//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [error, setError] = useState('');


//   const [isConnected, setIsConnected] = useState(false);
//   const [counterID, setCounterId] = useState('');
//   const [merchantID, setMerchantId] = useState('');
//   const [employeeID, setEmployeeId] = useState('');
//   const [merchantLatitude, setMerchantLatitude] = useState('');
//   const [merchantLongitude, setMerchantLongitude] = useState('');
//   const [employee, setEmployee] = useState(null); // Store employee details
//   const [deviceID, setDeviceID] = useState(''); // Store current device ID
//   const [latitude, setLatitude] = useState(''); // Store current latitude
//   const [longitude, setLongitude] = useState(''); // Store current longitude
//   const [counterQRURL, setCounterQRURL ] = useState(null); // counterQRURL
//   const [showQRCode, setShowQRCode] = useState(true); // State to control whether to show the QR code

//   const handleSocketMessage = useCallback(async (data) => {
//     try {

//   console.log('Received data:', data);

//   // Parse the message from WebSocket
//   const parsedMessage = JSON.parse(data);
//   console.log('Parsed WebSocket message:', parsedMessage);

//   // Extract and decrypt the encrypted message
//   const encryptedMessage = parsedMessage.message;
//   console.log('Encrypted message:', encryptedMessage);

//   const decryptedMessage = decrypt(encryptedMessage);
//   console.log('Decrypted message:', decryptedMessage);


// const abcd = JSON.parse(decryptedMessage);
// console.log('abcdDecrypted message:', abcd);

//   const decryptedMessage1 = decrypt(abcd);
//   console.log('Decrypted message1:', decryptedMessage1);



//   // Parse the decrypted message
//   const payload = JSON.parse(decryptedMessage1);
//   console.log('Final Payload:', payload);

//   // // Extract and decrypt the encrypted payload
//   // const parsedEncryptedPayload = parsedDecryptedMessage.encryptedPayload;
//   // console.log('Encrypted payload:', parsedEncryptedPayload);

//   // const finalDecryptedPayload = decrypt(parsedEncryptedPayload);
//   // console.log('Final decrypted payload:', finalDecryptedPayload);

//   // // Parse the final decrypted payload
//   // const payload = JSON.parse(finalDecryptedPayload);
//   // console.log('Parsed final payload:', payload);

//   // Extracting necessary fields from the payload
//   const nfcCounterID = payload.NFCCounterID;
//   const merchantID = payload.MerchantID;
//   const employeeID = payload.EmployeeID;
//   const merchantLatitude = payload.MerchantLatitude;
//   const merchantLongitude = payload.MerchantLongitude;

//   console.log('NFCCounterID:', nfcCounterID);
//   console.log('MerchantID:', merchantID);
//   console.log('EmployeeID:', employeeID);
//   console.log('Merchant Latitude:', merchantLatitude);
//   console.log('Merchant Longitude:', merchantLongitude);

//   const counterQRURL = 'https://hooks.ihex.in/counterqr/hexims/CounterQRC/QRCode/';
//   console.log('Counter QR URL:', counterQRURL);

//   // Get current location (latitude and longitude)
//   const location = await getLocation(); // Wait for the current location
//   const currentLatitude = location.latitude;
//   const currentLongitude = location.longitude;

//   console.log('Current location - Latitude:', currentLatitude, ', Longitude:', currentLongitude);
//   console.log('Socket message received, removing QR code.');

      
      
      
      
      
      
//       console.log('Socket message received, removing QR code.');
      
      
      
      
      
      
      
      
//       setShowQRCode(false); // Hide QR code on message reception
//       // Set state values
//       setMerchantLatitude(merchantLatitude);
//       setMerchantLongitude(merchantLongitude);
//       setCounterId(nfcCounterID);
//       setMerchantId(merchantID);
//       setEmployeeId(employeeID);
//       setCounterQRURL(counterQRURL);

//     // Step 1: Find the active device from the whitelist
// const activeDevice = whitelistDevices.find(device => 
//   device.merchantID === merchantID &&          // Matching merchant ID
//   device.counterID === nfcCounterID &&         // Matching counter ID (from NFC)
//   device.status === 'active'                   // Device status is 'active'
// );

// console.log("Active device:", activeDevice); //"Device status from whitelist:", activeDevice ? activeDevice.status : 'No active device found');
// console.log("Current device ID", deviceID);

// // Step 2: Fetch employee data matching the merchant and employee IDs
// const employeeData = employees.find(emp => 
//   emp.merchantId === merchantID && 
//   emp.employeeId === employeeID
// );

// // Step 3: Check if active device is found and it matches the current device ID
// if (activeDevice && activeDevice.deviceID === deviceID) {
//   // Calculate the distance between the current and merchant location
//   const distance = calculateDistance(currentLatitude, currentLongitude, merchantLatitude, merchantLongitude);
  
//   // Define acceptable margins for latitude and longitude
//   const latitudeMargin = 100;
//   const longitudeMargin = 100;

//   // Step 4: Check if the location is within the acceptable margin
//   if (distance <= 0.1 && 
//       Math.abs(currentLatitude - merchantLatitude) <= latitudeMargin &&
//       Math.abs(currentLongitude - merchantLongitude) <= longitudeMargin) {

//       // Step 5: If employee data is found, set the employee
//       if (employeeData) {
//           setEmployee(employeeData);  // Set the employee data in state or context
//           console.log("Employee found", employeeData);
//       } else {
//           console.log("Employee data not found.");
//       }
//   } else {
//       // Handle location mismatch
//       console.log('Failed to find employee due to location mismatch');
//   }
// } else {
//   // Handle case where no active device is found or current device doesn't match
//   console.log("No active device found or device ID mismatch.");
// }
//     } catch (error) {
//       console.error('Error processing socket message:', error);
//     }
//   }, []);

//   useEffect(() => {


 
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//           (position) => {
//               const { latitude, longitude } = position.coords;
//               setLocation({ latitude, longitude });
//               console.log('Latitude:', latitude, 'Longitude:', longitude);
//           },
//           (err) => {
//               setError('Error retrieving location: ' + err.message);
//               console.error(err);
//           }
//       );
//   } else {
//       setError('Geolocation is not supported by this browser.');
//   }

   

//     const initializeWebSocket = async () => {
//       const deviceId = await getDeviceId();
//       console.log('CurrentdeviceId:::', deviceId);
//       setDeviceID(deviceId); // Set device ID
//    //   console.log('ActiveDeviceId:', activeDevice);

//       const location = await getLocation(); // Get current location
//       setLatitude(location.latitude);
//       setLongitude(location.longitude);

//       const wss = new WebSocket(`wss://hooks.ihex.in:8086/?${deviceId}`);
//       console.log('SockeURL:', wss)
//       wss.onopen = () => setIsConnected(true);
//       wss.onmessage = (event) => handleSocketMessage(event.data);
//       wss.onclose = () => setIsConnected(false);
//       wss.onerror = (error) => console.error('WebSocket error:', error);

//       return () => wss.close();
//     };

//     initializeWebSocket();
//   }, 
//     [handleSocketMessage]
  
//   );

//   // Function to calculate distance using Haversine formula
//   const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 6371; // Radius of the Earth in kilometers
//     const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert to radians
//     const dLon = (lon2 - lon1) * (Math.PI / 180); // Convert to radians

//     const a = 
//         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//         Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
//         Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // Calculate the angular distance

//     return R * c; // Distance in kilometers
//   };

//   // Get current location function
//   const getLocation = () => {
//     return new Promise((resolve, reject) => {
//       navigator.geolocation.getCurrentPosition(
//           (position) => {
//               resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
//           },
//           (error) => {
//               console.error('Geolocation error:', error);
//               reject(error);
//           }
//       );
//     });
//   };

//   if (useIsElectron()) {
//  console.log('Electron:',currentLatitude
//   } else {
//     // // Browser-based logic (Chrome, etc.)
//     // Fingerprint2.get(components => {
//     //   const values = components.map(component => component.value);
//     //   const browserDeviceId = Fingerprint2.x64hash128(values.join(''), 31);
//     //   console.log('Browser Device ID:', browserDeviceId);
//     //   setDeviceID(browserDeviceId);
//     //   localStorage.setItem('deviceId', browserDeviceId);
//     // });
//    }

//   // Get device ID function
//   const getDeviceId = () => {
//     return new Promise((resolve) => {
//       const storedDeviceId = localStorage.getItem('deviceId');
//       if (storedDeviceId) {
//         resolve(storedDeviceId);
//       } else {
//         Fingerprint2.get((components) => {
//           const values = components.map((component) => component.value);
//           const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
//           localStorage.setItem('deviceId', deviceId);
//           resolve(deviceId);
//         });
//       }
//     });
//   };
// //console.log('counterQRURL', ("https://hooks.ihex.in/counterqr/hexims/CounterQRC/QRCode/OK001.png"));
// const imageUrl = `https://hooks.ihex.in/counterqr/hexims/CounterQRC/QRCode/OK001_qr.png`;
// const getrxLogo = "https://hooks.ihex.in/system/getrxLogo.png";;
// return (
//     <Router>
//       <div className="App">
//       <div>
//             {error && <p>{error}</p>}
//             {location.latitude !== null && location.longitude !== null ? (
//                 <p>
//                     Latitude: {location.latitude}, Longitude: {location.longitude}
//                 </p>
//             ) : (
//                 <p>Fetching location...</p>
//             )}
//         </div>
//       <div>
//             {isElectron ? (
//                 <h1>Running in Electron</h1>
//             ) : (
//                 <h1>Running in a web browser</h1>
//             )}
//         </div>

//         {!isConnected ? (
//           // <div>
//           //   <h1>GETrx</h1>
//           //   <div style={{ margin: '20px 0' }}>
//           //       <img
//           //         src={getrxLogo}
//           //          style={{ maxWidth: '500px', borderRadius: '8px' }}
//           //       />
//           //     </div>
//           //     <h1>INITALIZING</h1>
//           // </div>

//           <div style={{ margin: '20px 0' }}>
//           <img
//             src={imageUrl}
//             alt={`QR Code for ${counterID}`}
//             style={{ maxWidth: '500px', borderRadius: '8px' }}
//           />
//         </div>

//         ) : (
//           <div>
//             <h1>TAP NFC / SCAN QR</h1>

//             <h1>{counterID}</h1>
//              {/* Conditionally display the QR Code Image */}
//             {showQRCode && (
//               <div style={{ margin: '20px 0' }}>
//                 <img
//                   src={imageUrl}
//                   alt={`QR Code for ${counterID}`}
//                   style={{ maxWidth: '500px', borderRadius: '8px' }}
//                 />
//               </div>
//             )}

//             {employee && (
//               <div className="employee-details" style={{ border: '1px solid #ccc', padding: '10px', margin: '20px', borderRadius: '8px' }}>
//                 <h2>{employee.name}</h2>
//                 <h2>Logged in at {counterID}</h2>
//                 <p><strong>Login Time:</strong> {new Date().toLocaleString()}</p>
//                 <p><strong>Employee ID:</strong> {employee.employeeId}</p>
//                 <p><strong>Email:</strong> {employee.email}</p>
//                 <p><strong>Phone:</strong> {employee.phone}</p>
//                 <button onClick={() => window.location.reload()}>Log Out</button> {/* Logout button to reload page */}
//               </div>
//             )}
//           </div>
//         )}
//         {/* Add your Routes here if needed */}
//       </div>
//     </Router>
//   );
// }

// export default App;


import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import CryptoJS from 'crypto-js';
import Fingerprint2 from 'fingerprintjs2';
import { BrowserRouter as Router } from 'react-router-dom';

const useIsElectron = () => {
  const [isElectron, setIsElectron] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && window.process && window.process.versions && window.process.versions.electron) {
      setIsElectron(true);
    }
  }, []);

  return isElectron;
};

const IV_LENGTH = 16; // AES IV length
const ENCRYPTION_KEY = 'x988707222798870722279887072227x'; // AES-256 encryption key

const employees = [
  { employeeId: 'OKZIM000123', name: 'ASHISH JAIN', email: 'aj@getransfer.co.zw', phone: '91.98870.72227', merchantId: 'okZimbabwexx01' },
];

// Whitelist devices
const whitelistDevices = [
  { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: 'device123', status: 'inactive' },
  { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '527c80f118e70acfa27877c818aede08', status: 'inactive' },
  { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '34fb606cd8467d31f7452e24a8d9f7ce', status: 'active' },
  { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '22df6337f288e481f53a78a5e601f97f', status: 'active' },
  { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '956942087fe19fd07f9f613e750ba202', status: 'inactive' },
  { merchantID: 'okZimbabwexx01', counterID: 'OK001', deviceID: '4dfd099fbfe09a7e319ff9b29129b0e9', status: 'inactive' },
];

const wordArrayToBase64 = (wordArray) => CryptoJS.enc.Base64.stringify(wordArray);
const base64ToWordArray = (base64String) => CryptoJS.enc.Base64.parse(base64String);

const encrypt = (text) => {
  const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);
  const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
  const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
  return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString();
};

const decrypt = (encryptedData) => {
  const [ivString, encryptedText] = encryptedData.split(':');
  const iv = CryptoJS.enc.Base64.parse(ivString);
  const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

function App() {
  const isElectron = useIsElectron();
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [counterID, setCounterId] = useState('');
  const [merchantID, setMerchantId] = useState('');
  const [employeeID, setEmployeeId] = useState('');
  const [merchantLatitude, setMerchantLatitude] = useState('');
  const [merchantLongitude, setMerchantLongitude] = useState('');
  const [employee, setEmployee] = useState(null);
  const [deviceIDD, setDeviceID] = useState('');
  const [CurrentdeviceId, setCurrentdeviceId] = useState('');  
  const [showQRCode, setShowQRCode] = useState(true);
//   const handleSocketMessage = useCallback(async (data) => {
//     try {
//       console.log('Received data:', data);
//       // const parsedMessage = JSON.parse(data);
//       // const decryptedMessage = decrypt(parsedMessage.message);
//       // const payload = JSON.parse(decryptedMessage);
//       console.log('Received data:', data);

//       // Parse the message from WebSocket
//       const parsedMessage = JSON.parse(data);
//       console.log('Parsed WebSocket message:', parsedMessage);
    
//       // Extract and decrypt the encrypted message
//       const encryptedMessage = parsedMessage.message;
//       console.log('Encrypted message:', encryptedMessage);
    
//       const decryptedMessage = decrypt(encryptedMessage);
//       console.log('Decrypted message:', decryptedMessage);
    
    
//     const abcd = JSON.parse(decryptedMessage);
//     console.log('abcdDecrypted message:', abcd);
    
//       const decryptedMessage1 = decrypt(abcd);
//       console.log('Decrypted message1:', decryptedMessage1);
    
    
    
//       // Parse the decrypted message
//       const payload = JSON.parse(decryptedMessage1);
//       console.log('Final Payload:', payload);

//       const { NFCCounterID, MerchantID, EmployeeID, MerchantLatitude, MerchantLongitude } = payload;
//       console.log('Payload:', payload);

//       // Get current location (latitude and longitude)
//       const currentLocation = await getLocation();
//       console.log('Current location:', currentLocation);

//       setShowQRCode(false);
//       setMerchantLatitude(MerchantLatitude);
//       setMerchantLongitude(MerchantLongitude);
//       setCounterId(NFCCounterID);
//       setMerchantId(MerchantID);
//       setEmployeeId(EmployeeID);

//       const activeDevice = whitelistDevices.find(device => 
//         device.merchantID === MerchantID &&
//         device.counterID === NFCCounterID && 
//         device.status === 'active'
//       );



//       console.log("Active device:", activeDevice); //"Device status from whitelist:", activeDevice ? activeDevice.status : 'No active device found');
// console.log("Current device ID", deviceId);



// // Step 2: Fetch employee data matching the merchant and employee IDs
// const employeeData = employees.find(emp => 
//   emp.merchantId === merchantID && 
//   emp.employeeId === employeeID
// );
//   //     if (activeDevice && activeDevice.deviceID === deviceID) {
//   //       // Calculate the distance and check if within margin
//   //       const distance = calculateDistance(currentLocation.latitude, currentLocation.longitude, MerchantLatitude, MerchantLongitude);
//   //       if (distance <= 0.1) {
//   //         employeeData = employees.find(emp => emp.merchantId === MerchantID && emp.employeeId === EmployeeID);
//   //         if (employeeData) {
//   //           setEmployee(employeeData);
//   //           console.log("Employee found", employeeData);
//   //         } else {
//   //           console.log("Employee data not found.");
//   //         }
//   //       } else {
//   //         console.log('Location mismatch.');
//   //       }
//   //     } else {
//   //       console.log("No active device found or device ID mismatch.");
//   //     }
//   //   } catch (error) {
//   //     console.error('Error processing socket message:', error);
//   //   }
//   // }, []);
// console.log("Active Decice:::::",activeDevice);
// console.log("Active Decice.deviceID:::::", activeDevice.deviceID);
// console.log("Current Decice:::::",deviceId);


//   // Step 3: Check if active device is found and it matches the current device ID
// if (activeDevice && activeDevice.deviceID === deviceId) {
//   // Calculate the distance between the current and merchant location
//   const distance = calculateDistance(currentLocation.latitude, currentLocation.longitude, merchantLatitude, merchantLongitude);
  
//   // Define acceptable margins for latitude and longitude
//   const latitudeMargin = 100;
//   const longitudeMargin = 100;

//   // Step 4: Check if the location is within the acceptable margin
//  // if (distance <= 0.1 && 
//         if ((distance <= 0.1) &&

//       Math.abs(currentLocation.latitude - merchantLatitude) <= latitudeMargin &&
//       Math.abs(currentLocation.longitude - merchantLongitude) <= longitudeMargin) {

//       // Step 5: If employee data is found, set the employee
//       if (employeeData) {
//           setEmployee(employeeData);  // Set the employee data in state or context
//           console.log("Employee found", employeeData);
//       } else {
//           console.log("Employee data not found.");
//       }
//   } else {
//       // Handle location mismatch
//       console.log('Failed to find employee due to location mismatch');
//   }
// } else {
//   // Handle case where no active device is found or current device doesn't match
//   console.log("No active device found or device ID mismatch.");
// }
//     } catch (error) {
//       console.error('Error processing socket message:', error);
//     }
//   }, []);
const handleSocketMessage = useCallback(async (data) => {
  try {

    // Log received data
    //console.log('Received data:', data);
      // const parsedMessage = JSON.parse(data);
      // const decryptedMessage = decrypt(parsedMessage.message);
      // const payload = JSON.parse(decryptedMessage);
      console.log('Received data:', data);

      // Parse the message from WebSocket
      const parsedMessage = JSON.parse(data);
      console.log('Parsed WebSocket message:', parsedMessage);
    
      // Extract and decrypt the encrypted message
      const encryptedMessage = parsedMessage.message;
      console.log('Encrypted message:', encryptedMessage);
    
      const decryptedMessage = decrypt(encryptedMessage);
      console.log('Decrypted message:', decryptedMessage);
    
    
    const abcd = JSON.parse(decryptedMessage);
    console.log('abcdDecrypted message:', abcd);
    
      const decryptedMessage1 = decrypt(abcd);
      console.log('Decrypted message1:', decryptedMessage1);
    
    
    
      // Parse the decrypted message
      const payload = JSON.parse(decryptedMessage1);
      console.log('Final Payload:', payload);

      const { NFCCounterID, MerchantID, EmployeeID, MerchantLatitude, MerchantLongitude } = payload;
      console.log('Payload:', payload);
    // Get current location (latitude and longitude)




    // Set QR code visibility and merchant details
    setShowQRCode(false);
    setMerchantLatitude(MerchantLatitude);
    setMerchantLongitude(MerchantLongitude);
    setCounterId(NFCCounterID);
    setMerchantId(MerchantID);
    setEmployeeId(EmployeeID);

    // Check for active device in whitelist
    const activeDevice = whitelistDevices.find(device =>
      device.merchantID === MerchantID &&
      device.counterID === NFCCounterID && 
      device.status === 'active'
    );


    if (useIsElectron) { 

      console.log("Active device:", activeDevice);
      // const ccdd = await getDeviceId();
      // console.log("Current device IDDDDDD", ccdd);
      
          if (activeDevice && activeDevice.deviceID)
          {
            // === ccdd) {
            // Calculate distance between current and merchant location
            // const distance = calculateDistance(currentLocation.latitude, currentLocation.longitude, MerchantLatitude, MerchantLongitude);
            // console.log("Distance:", distance);
            
            // // Define acceptable margin
            // const margin = 0.1; // 100 meters
      
            // // Check if the distance is within acceptable limits
            // if (distance <= margin) {
            //   // Fetch employee data
              const employeeData = employees.find(emp => 
                emp.merchantId === MerchantID && 
                emp.employeeId === EmployeeID
              );
              console.log(employeeData)
              if (employeeData) {
                setEmployee(employeeData); // Set employee data


                const deviceId = await getDeviceId();
                console.log('Current deviceId:', deviceId);
                setDeviceID(deviceId);
                setCurrentdeviceId(deviceId)
                console.log('current deiiiviceidididid:',CurrentdeviceId);
          
             

                const wss = new WebSocket(`wss://hooks.ihex.in:8086/?${deviceId}`);
                console.log("Employee found", employeeData);

                const to = '9887072227';
                const message = "Logged In";
                wss.onopen = () => {
                  console.log('WebSocket connection opened');
                  setIsConnected(true);
                  const payload = {
                    to: to,
                    message: message
                  };

                  // Send the payload as a string
                  wss.send(JSON.stringify(payload));
                  console.log('Message sent:', payload);
                };
                // wss.onerror = (error) => {
                //   console.error('WebSocket error:', error);
                // };

                // Make sure the WebSocket connection is open
                if (wss.readyState === WebSocket.OPEN) {
                  // Create the payload to send
                  const payload = {
                    to: to,
                    message: message
                  };

                  // Send the payload as a string
                  wss.send(JSON.stringify(payload));

            console.log('Message sent:', payload);
          } else {
            console.log('WebSocket connection is not open');
          }
        } else {
          console.log("Employee data not found.");
        }
      } else {
        console.log('Failed to find employee due to location mismatch');
      }
    }

    else {

    const currentLocation = await getLocation();
    console.log('Current location:', currentLocation);

    console.log("Active device:", activeDevice);
const ccdd = await getDeviceId();
console.log("Current device IDDDDDD", ccdd);

    if (activeDevice && activeDevice.deviceID === ccdd) {
      // Calculate distance between current and merchant location
      const distance = calculateDistance(currentLocation.latitude, currentLocation.longitude, MerchantLatitude, MerchantLongitude);
      console.log("Distance:", distance);
      
      // Define acceptable margin
      const margin = 0.1; // 100 meters

      // Check if the distance is within acceptable limits
      if (distance <= margin) {
        // Fetch employee data
        const employeeData = employees.find(emp => 
          emp.merchantId === MerchantID && 
          emp.employeeId === EmployeeID
        );
        console.log(employeeData)
        if (employeeData) {
          setEmployee(employeeData); // Set employee data
          console.log("Employee found", employeeData);

          const deviceId = await getDeviceId();
          console.log('Current deviceId:', deviceId);
          setDeviceID(deviceId);
          setCurrentdeviceId(deviceId)
          console.log('current deiiiviceidididid:',CurrentdeviceId);
    
       

          const wss = new WebSocket(`wss://hooks.ihex.in:8086/?${deviceId}`);
          console.log("Employee found", employeeData);

          const to = '9887072227';
          const message = "Logged In";
          wss.onopen = () => {
            console.log('WebSocket connection opened');
            setIsConnected(true);
            const payload = {
              to: to,
              message: message
            };

            // Send the payload as a string
            wss.send(JSON.stringify(payload));
            console.log('Message sent:', payload);
          };
          // wss.onerror = (error) => {
          //   console.error('WebSocket error:', error);
          // };

          // Make sure the WebSocket connection is open
          if (wss.readyState === WebSocket.OPEN) {
            // Create the payload to send
            const payload = {
              to: to,
              message: message
            };

            // Send the payload as a string
            wss.send(JSON.stringify(payload));

      console.log('Message sent:', payload);
    } else {
      console.log('WebSocket connection is not open');
    }


        } else {
          console.log("Employee data not found.");
        }
      } else {
        console.log('Failed to find employee due to location mismatch');
      }
    } 
    
    else {
      console.log("No active device found or device ID mismatch.");
    }}
  } catch (error) {
    console.error('Error processing socket message:', error);
  }
}, []);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          console.log('Latitude:', latitude, 'Longitude:', longitude);
        },
        (err) => {
          setError('Error retrieving location: ' + err.message);
          console.error(err);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }

    const initializeWebSocket = async () => {
      const deviceId = await getDeviceId();
      console.log('Current deviceId:', deviceId);
      setDeviceID(deviceId);
      setCurrentdeviceId(deviceId)
      console.log('current deiiiviceidididid:',CurrentdeviceId);


      const wss = new WebSocket(`wss://hooks.ihex.in:8086/?${deviceId}`);
      wss.onopen = () => {
        console.log('WebSocket connection opened');
        setIsConnected(true);
      };
      wss.onmessage = (event) => handleSocketMessage(event.data);
      wss.onclose = () => {
        console.log('WebSocket connection closed');
        setIsConnected(false);
      };
      wss.onerror = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
      };
      
      



      return () => wss.close();
    };

    initializeWebSocket();
  }, [handleSocketMessage]);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
        },
        (error) => {
          console.error('Geolocation error:', error);
          reject(error);
        }
      );
    });
  };

  const getDeviceId = () => {
    return new Promise((resolve) => {
      const storedDeviceId = localStorage.getItem('deviceId');
      if (storedDeviceId) {
        resolve(storedDeviceId);
      } else {
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
          localStorage.setItem('deviceId', deviceId);
          setDeviceID(deviceId);
          setCurrentdeviceId(deviceId);
          resolve(deviceId);
        });
      }
    });
  };

  const imageUrl = `https://hooks.ihex.in/counterqr/hexims/CounterQRC/QRCode/OK001_qr.png`;
  const getrxLogo = "https://hooks.ihex.in/system/getrxLogo.png";

//   return (
//     <Router>
//       <div className="App">
//         <div>
//           {error && <p>{error}</p>}
//           {location.latitude !== null && location.longitude !== null ? (
//             <p>
//               Latitude: {location.latitude}, Longitude: {location.longitude}
//             </p>
//           ) : (
//             <p>Fetching location...</p>
//           )}
//         </div>
//         <div>
//           {isElectron ? <h1>Running in Electron</h1> : <h1>Running in a web browser</h1>}
//         </div>

//         {!isConnected ? (
//           <div style={{ margin: '20px 0' }}>
//             <img
//               src={imageUrl}
//               alt={`QR Code for ${counterID}`}
//               style={{ maxWidth: '500px', borderRadius: '8px' }}
//             />
//           </div>
//         ) : (
//           <div>
//             <h1>TAP NFC / SCAN QR CODE</h1>
//             {showQRCode && (
//               <div style={{ margin: '20px 0' }}>
//                 <img
//                   src={imageUrl}
//                   alt={`QR Code for ${counterID}`}
//                   style={{ maxWidth: '500px', borderRadius: '8px' }}
//                 />
//               </div>
//             )}
//             {employee && (
//               <div>
//                 <h2>Welcome, {employee.name}!</h2>
//                 <p>Email: {employee.email}</p>
//                 <p>Phone: {employee.phone}</p>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </Router>
//   );
// }
 // Function to request location permission
 const requestLocationPermission = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
        console.log('Latitude:', latitude, 'Longitude:', longitude);
        // Reload the window after getting location
        window.location.reload();
      },
      (err) => {
        setError('Error retrieving location: ' + err.message);
        console.error(err);
      }
    );
  } else {
    setError('Geolocation is not supported by this browser.');
  }
};

return (
  <Router>
    <div className="App">
    <div>
    <button onClick={requestLocationPermission}>
        Request Location Permission & Reload
      </button>

          {error && <p>{error}</p>}
          {location.latitude !== null && location.longitude !== null ? (
              <p>
                  Latitude: {location.latitude}, Longitude: {location.longitude}
              </p>
          ) : (
              <p>Fetching location...</p>
          )}
      </div>
    <div>
          {isElectron ? (
              <h1>Running in Electron</h1>
          ) : (
              <h1>Running in a web browser</h1>
          )}
      </div>

      {!isConnected ? (
        <div>
          <h1>GETrx</h1>
          <div style={{ margin: '20px 0' }}>
              <img
                src={getrxLogo}
                 style={{ maxWidth: '500px', borderRadius: '8px' }}
              />
            </div>
            <h1>INITALIZING</h1>
        </div>



      ) : (
        <div>
          <h1>TAP NFC / SCAN QR</h1>

          <h1>{counterID}</h1>
           {/* Conditionally display the QR Code Image */}
          {showQRCode && (
            <div style={{ margin: '20px 0' }}>
              <img
                src={imageUrl}
                alt={`QR Code for ${counterID}`}
                style={{ maxWidth: '500px', borderRadius: '8px' }}
              />
            </div>
          )}

          {employee && (
            <div className="employee-details" style={{ border: '1px solid #ccc', padding: '10px', margin: '20px', borderRadius: '8px' }}>
              <h2>{employee.name}</h2>
              <h2>Logged in at {counterID}</h2>
              <p><strong>Login Time:</strong> {new Date().toLocaleString()}</p>
              <p><strong>Employee ID:</strong> {employee.employeeId}</p>
              <p><strong>Email:</strong> {employee.email}</p>
              <p><strong>Phone:</strong> {employee.phone}</p>
              <button onClick={() => window.location.reload()}>Log Out</button> {/* Logout button to reload page */}
            </div>
          )}
        </div>
      )}
      {/* Add your Routes here if needed */}
    </div>
  </Router>
);
}

export default App;